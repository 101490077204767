@import "~@ui/styles/variables";
@import "~@ui/styles/tools";
@import "@app/styles/variables";

.badge {
    padding: 13px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: $color-off-white;
    gap: 4px;
    border-radius: $border-radius;
}

.badge--value {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.3px;
}


.badge--icon {
    width: 16px;
    height: 16px;

    img {
        width: 100%;
        height: 100%;
    }
}